export function getDate(pos = "", num = 0){
  var myDate = new Date();
  switch(pos){
    case "month":
      var month = ('0' + (myDate.getMonth() + 1 + num)).slice(-2);
      var date = ('0' + myDate.getDate()).slice(-2);
      var year = myDate.getFullYear();
      break;
    case "date":
      var month = ('0' + (myDate.getMonth() + 1)).slice(-2);
      var date = ('0' + (myDate.getDate() + num)).slice(-2);
      var year = myDate.getFullYear();
      break;
    default:
      var month = ('0' + (myDate.getMonth() + 1)).slice(-2);
      var date = ('0' + myDate.getDate()).slice(-2);
      var year = myDate.getFullYear();
      break;
  }
  if(month=="00"){month = "01"};
  if(date=="00"){date = "01"};
  return year + '-' + month + '-' + date;
  //return date + '-' + month + '-' + year;
}

export function formatDate(date, format, splitter = '-'){
  if(date){
    let res = date.split(splitter);
    let year, month, day;
    const zero = "0";
    var length0 = res[0].toString().length;
    var length1 = res[1].toString().length;
    var length2 = res[2].toString().length;
    if(length0 == 1){
      res[0] = zero+res[0];
    }
    if(length1 == 1){
      res[1] = zero+res[1];
    }
    if(length2 == 1){
      res[2] = zero+res[2];
    }
    if(res[0].length > 2){
      year = res[0].substr(0,4);
      day = res[2].substr(0,2);
    }else{
      year = res[2].substr(0,4);
      day = res[0].substr(0,2);
    }
    month = res[1].substr(0,2);
    switch(format){
      case "dd/mm/yyyy":
        return day + '/' + month + '/' + year;
      case "yyyy/mm/dd":
        return year + '/' + month + '/' + day;
      case "dd-mm-yyyy":
        return day + '-' + month + '-' + year;
      case "yyyy-mm-dd":
        return year + '-' + month + '-' + day;
      case "mm/dd/yyyy":
        return month + '/' + day + '/' + year;
      default:
        return '';
    }
  }
}

export function recurringDates(startDate, endDate, interval, editing = 0) {
  // initialize date variable with start date
  var date = new Date(startDate);
  var dateEnd = new Date(endDate);

  // create array to hold result dates
  if(editing){
    var dates = [];
  }else{
    var dates = [date];
  }

  // check for dates in range
  while ((date = addDays(date, interval)) <= dateEnd) {
    // add new date to array
    dates.push(date);
  }
  for(let i=0; i<dates.length; i++){
    dates[i] = dates[i].getFullYear()+"-"+(dates[i].getMonth() + 1)+"-"+dates[i].getDate();
  }
  // return result dates
  return dates;
}

function addDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}
function subtractDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
}

export const removeAccents = (str) => {
  return str.normalize('NFD')
  .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
  .normalize();
} 

export function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function returnError(err){
  switch(err){
    case 400:
      return "Error en la aplicación. Consulte a soporte técnico.";
    case 401:
      return "No tienes permiso para llevar a cabo esta operación.";
    case 500:
      return "Error en el servidor. Consulte a soporte técnico.";
  }
}

export function csvToObject(csv, separator = ';', newLine = '\n') {
  return new Promise(function (resolve) {
    const lines = csv.split(newLine);
    const headers = lines[0].split(separator);
    const result = [];
    for (let i = 1; i < lines.length; i++) {
      if (lines[i].length > 1) {
        const data = lines[i].split(separator);
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = data[j]?.trim();
        }
        result.push(obj);
      }
    }
    resolve(result);
  });
}

export var tablesToExcel = (function() {
  var uri = 'data:application/vnd.ms-excel;base64,'
  , tmplWorkbookXML = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">'
    + '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Axel Richter</Author><Created>{created}</Created></DocumentProperties>'
    + '<Styles>'
    + '<Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style>'
    + '<Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style>'
    + '</Styles>' 
    + '{worksheets}</Workbook>'
  , tmplWorksheetXML = '<Worksheet ss:Name="{nameWS}"><Table>{rows}</Table></Worksheet>'
  , tmplCellXML = '<Cell{attributeStyleID}{attributeFormula}><Data ss:Type="{nameType}">{data}</Data></Cell>'
  , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
  , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
  return function(tables, wsnames, wbname, appname) {
    var ctx = "";
    var workbookXML = "";
    var worksheetsXML = "";
    var rowsXML = "";

    for (var i = 0; i < tables.length; i++) {
      if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i]);
      //Elimino div que añade Vue a las tablas
      tables[i]=tables[i].firstChild.firstChild;
      for (var j = 0; j < tables[i].rows.length; j++) {
        rowsXML += '<Row>'
        for (var k = 0; k < tables[i].rows[j].cells.length; k++) {
          var dataType = tables[i].rows[j].cells[k].getAttribute("data-type");
          var dataStyle = tables[i].rows[j].cells[k].getAttribute("data-style");
          var dataValue = tables[i].rows[j].cells[k].getAttribute("data-value");
          dataValue = (dataValue)?dataValue:tables[i].rows[j].cells[k].innerHTML;
          var dataFormula = tables[i].rows[j].cells[k].getAttribute("data-formula");
          dataFormula = (dataFormula)?dataFormula:(appname=='Calc' && dataType=='DateTime')?dataValue:null;
          ctx = {  attributeStyleID: (dataStyle=='Currency' || dataStyle=='Date')?' ss:StyleID="'+dataStyle+'"':''
                 , nameType: (dataType=='Number' || dataType=='DateTime' || dataType=='Boolean' || dataType=='Error')?dataType:'String'
                 , data: (dataFormula)?'':dataValue
                 , attributeFormula: (dataFormula)?' ss:Formula="'+dataFormula+'"':''
                };
          rowsXML += format(tmplCellXML, ctx);
        }
        rowsXML += '</Row>'
      }
      ctx = {rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i};
      worksheetsXML += format(tmplWorksheetXML, ctx);
      rowsXML = "";
    }

    ctx = {created: (new Date()).getTime(), worksheets: worksheetsXML};
    workbookXML = format(tmplWorkbookXML, ctx);



    var link = document.createElement("A");
    link.href = uri + base64(workbookXML);
    link.download = wbname || 'Workbook.xls';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
})();