import Vue from 'vue'
import Vuex from 'vuex'
import genericReload from '../components/genericReload';
import createPersistedState from "vuex-persistedstate";
import * as basics from "../components/basics.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: '',
    idUser: 0,
    user: '',
    typeUser: '',
    clients: [],
    professionals: [],
    providers: [],
    professionalsTypes: [{value: "admin", name: "Administrador"},{value: "coord", name: "Coordinador"},{value: "trabajador", name: "Trabajador"}],
    workTypes: [],
    expenses: [],
    expenseTypes: [],
    equipment: [],
    clientSelected: null, // Client selected on Clientes's view to assign a new appointment in calendar
    keepAlive: true,
    filterCalendarPro: null,
    filterCalendarPT: null,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['auth', 'idUser', 'user', 'typeUser'],
    }),
  ],
  getters: {
    clientById: (state) => (id) => {
      return state.clients.find(client => client.id === id);
    },
    clientByName: (state) => (name, surname) => {
      return state.clients.find(client => client.nombre.toUpperCase() === name && client.apellidos.toUpperCase() === surname);
    },
    professionalById: (state) => (id) => {
      return state.professionals.find(professional => professional.id === id);
    },
    equipmentById: (state) => (id) => {
      return state.equipment.find(eq => eq.id === id);
    },
    providerById: (state) => (id) => {
      return state.providers.find(p => p.id === id);
    },
    expenseTypeById: (state) => (id) => {
      return state.expenseTypes.find(p => p.id === id);
    },
  },
  mutations: {
    modifyAuth(state, newAuth){
      state.auth = newAuth
    },
    modifyClientSelected(state, newValue){
      state.clientSelected = newValue
    },
    modifyUser(state, newId){
      state.idUser = newId;
    },
    modifyNameUser(state, newName){
      state.user = newName;
    },
    modifyTypeUser(state, newType){
      state.typeUser = newType;
    },
    modifyKeepAlive(state, val){
      state.keepAlive = val;
    },
    addPatient(state, newClient){
      state.clients.push(newClient);
      state.clients = state.clients.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
  },
  actions: {
    async loadData(){
      if(this.state.auth != ''){
        await this.dispatch("getClients");
        await this.dispatch("getProfessionals");
        await this.dispatch("getWorkTypes");
        await this.dispatch("getExpenseTypes");
        await this.dispatch("getEquipment");
        await this.dispatch("getProviders");
      }
    },
    async getClients(){
      const response = await genericReload(this.state.auth, 'api/client');
      if (response.status === 200) {
        this.state.clients = await response.json();
      }
    },
    async getProfessionals(){
      const response = await genericReload(this.state.auth, 'api/user');
      if (response.status === 200) {
        this.state.professionals = await response.json();
      }
    },
    async getWorkTypes(){
      const response = await genericReload(this.state.auth, 'api/work_type');
      if (response.status === 200) {
        this.state.workTypes = await response.json();
      }
    },
    async getExpenseTypes(){
      const response = await genericReload(this.state.auth, 'api/expense_type');
      if (response.status === 200) {
        this.state.expenseTypes = await response.json();
      }
    },
    async getEquipment(){
      const response = await genericReload(this.state.auth, 'api/equipment');
      if (response.status === 200) {
        this.state.equipment = await response.json();
      }
    },
    async getProviders(){
      const response = await genericReload(this.state.auth, 'api/provider');
      if (response.status === 200) {
        this.state.providers = await response.json();
      }
    },
  },
  modules: {
  }
})
