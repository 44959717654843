import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.$route.name != 'Login' && _vm.$route.name != 'RecoverPass')?_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"RCT Logo","contain":"","src":require("@/assets/logo2.png"),"transition":"scale-transition","width":"60"}})],1),_c(VSpacer),_c(VBtn,{attrs:{"href":"https://rctingenieria.es/","target":"_blank","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("RCTIngenieria.es")]),_c(VIcon,[_vm._v("mdi-web")])],1)],1):_vm._e(),_c(VMain,[(_vm.$route.name != 'Login' && _vm.$route.name != 'RecoverPass' && _vm.$route.name != 'Newsletter')?_c('NavBar'):_vm._e(),_c(VContainer,{staticClass:"cMain",attrs:{"fluid":""}},[(this.$store.state.keepAlive)?_c('keep-alive',{attrs:{"include":"Clientes"}},[_c('router-view')],1):_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }