import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import RecoverPass from '../components/RecoverPass.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login - RCT"
    }
  },
  {
    path: '/recoverPassword',
    name: 'RecoverPass',
    component: RecoverPass,
    meta: {
      title: "Recuperar contraseña - RCT"
    },
  },
  {
    path: '/inicio',
    name: 'Inicio',
    meta: {
      title: "Inicio - RCT"
    },
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Inicio.vue')
  },
  {
    path: '/proyectos',
    name: 'Proyectos',
    meta: {
      title: "Proyectos - RCT"
    },
    component: () => import(/* webpackChunkName: "Proyectos" */ '../views/Proyectos.vue')
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    meta: {
      title: "Proveedores - RCT"
    },
    component: () => import(/* webpackChunkName: "Proveedores" */ '../views/Proveedores.vue')
  },
  {
    path: '/admin',
    name: 'Panel de administración',
    meta: {
      title: "Panel de administración - RCT"
    },
    component: () => import(/* webpackChunkName: "Envios" */ '../views/Admin.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.name == 'RecoverPass'){
    next();
  }else{
    if (to.name !== 'Login' && (store.state.auth=='' || store.state.auth==undefined)){
      next({ name: 'Login' })
    }else{
      if((to.name == 'Panel de administración' && store.state.typeUser!='admin')){
        next({ name: 'Login' })
      }else{
        next()
      }
    }
  }
})

export default router
